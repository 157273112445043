import { BasicsClass } from '@/common/BasicsClass';
export class MarketDepartDataStatisticAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['getMarketDDataStatistic', {
            url: '/admin/market/list',
            name: 'list',
            label: '列表'
        }],
        ['addMarketDDataStatistic', {
            url: '/admin/market/add',
            name: 'add',
            label: '添加'
        }],
        ['updateMarketDDataStatistic', {
            url: '/admin/market/edit',
            name: 'edit',
            label: '编辑'
        }],
        ['exportExcelMarketDDataStatistic', {
            url: '/admin/market/export',
            name: 'export-excel',
            label: '导出'
        }],

        // ['deleteMarketDDataStatisticItem', {
        //     url: '/admin/coupons/delete',
        //     name: 'delete',
        //     label: '删除'
        // }],
        ['searchVenueList', {
            url: '/admin/coupons/shop/list',
            name: 'shop-list',
            label: '店铺列表',
            // hidden: true
        }],
        ['getClueSourceList', {
            url: '/admin/market/sourceAll',
            name: 'clue-source-list',
            label: '获取来源列表',
            // hidden: true
        }],
        ['poolTableCategory', {
            url: '/admin/poolTableCategory/list',
            name: 'pool-category',
            label: '台桌/包厢类型',
            // hidden: true
        }],
    ])


    //新增市场部数据统计
    public addMarketDDataStatistic(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('addMarketDDataStatistic').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    //修改市场部数据统计
    public updateMarketDDataStatistic(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('updateMarketDDataStatistic').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    //市场部数据统计列表
    public getMarketDDataStatistic(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getMarketDDataStatistic').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 导出市场部数据统计
    public exportExcelMarketDDataStatistic(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('exportExcelMarketDDataStatistic').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
    // 删除 市场部数据统计
    // public deleteMarketDDataStatisticItem(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    //     const apiUrls = this.apiUrls

    //     const url = apiUrls.get('deleteMarketDDataStatisticItem').url

    //     this.BasicPost(url, {
    //         ...params
    //     }, false, false, false, successCb, failCb, showLoading)
    // }

    // 搜索店铺列表
    public searchVenueList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('searchVenueList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }


    //线索来源列表
    public getClueSourceList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getClueSourceList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    //球桌分类列表
    public poolTableCategory(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('poolTableCategory').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
}